import { StyleContextProvider } from '/machinery/StyleContext'

import context from '/cssGlobal/style-context.css'
import styles from './Section.css'

export function SectionWhite({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextWhite} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionBlack({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextBlack} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionSand({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextSand} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionClay({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextClay} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionGray({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextGray} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionHoliday({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return <Base styleContext={context.contextHoliday} {...{ children, id, padding, dataX, layoutClassName }} />
}

export function SectionHolidaySparkles({ children, id = undefined, dataX, padding = undefined, layoutClassName = undefined }) {
  return (
    <Base
      styleContext={context.contextHoliday}
      className={styles.componentHolidaySparkles}
      {...{ children, id, padding, dataX, layoutClassName }}
    />
  )
}

function Base({ children, id = undefined, styleContext, dataX, padding = undefined, className = undefined, layoutClassName = undefined, includePadding = true }) {
  const keyExistsInThemeContext = Object.values(context).includes(styleContext)
  const paddingValueExists = [false, undefined, 'lg', 'md', 'sm'].includes(padding)

  if (!keyExistsInThemeContext) throw new Error(`StyleContext prop passed on 'Section' with value '${styleContext}' does not exist in style-contexts.css.`)
  if (!paddingValueExists) throw new Error(`Section does not accept '${padding}' as value for padding.`)

  return (
    <section
      data-x={dataX}
      data-style-context={styleContext}
      className={cx(className, styles.componentBase, includePadding && styles.padding, styles[padding], layoutClassName)}
      {...(id ? { id } : {})}
    >
      <StyleContextProvider {...{ children, styleContext }} />
    </section>
  )
}
