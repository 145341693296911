import { routeMap } from '/routeMap'
import { makeSlug } from '/machinery/makeSlug'

export function createInstoreUrl({ locationName, stepId, host }) {
  const removeLanguage = 'removeLanguage'

  return host + routeMap.app.instore({
    title: makeSlug({ input: locationName, language: 'en' }),
    stepId,
    language: removeLanguage
  }).replace(`/${removeLanguage}`, '')
}
