import { useLocation } from '@kaliber/routing'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useQueryString } from '@kaliber/sanity-routing/queryString'
import Fuse from 'fuse.js'

import { useClientConfig } from '/machinery/ClientConfig'
import { createInstoreUrl } from '/machinery/createInstoreUrl'
import { useReportError } from '/machinery/ReportError'
import { useDebounced } from '/machinery/useDebounced'
import { handleResponse } from '/machinery/handleResponse'
import { useTranslate } from '/machinery/I18n'
import { FORBIDDEN } from '/machinery/statusCodes'
import { routeMap } from '/routeMap'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { FormFieldSelectLightSimple, FormFieldTextLightSimple } from '/features/buildingBlocks/FormField'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ButtonGhostRoundedIcon } from '/features/buildingBlocks/Button'
import { TextSm } from '/features/buildingBlocks/Text'
import { UppercaseHeadingXlTitleSubTitle } from '/features/buildingBlocks/UppercaseHeading'
import { SectionWhite } from '/features/pageOnly/Section'


import styles from './InstoreSearchPage.css'

import iconArrowRight from '/images/icons/arrow-right.raw.svg'

const queryClient = new QueryClient()

export function InstoreSearchPage() {
  const isMounted = useRenderOnMount()

  if (!isMounted) return <div>Loading</div>

  return (
    <QueryClientProvider client={queryClient}>
      <Page />
    </QueryClientProvider>
  )
}

function Page() {
  const { __ } = useTranslate()
  const reportError = useReportError()
  const location = useLocation()

  const loginUrl = `${routeMap.api.v1.auth.login()}?${new URLSearchParams({ returnPath: location.pathname }).toString()}`
  const [{ country = '', city = '', textQuery = '' }, setQueryString] = useQueryString()
  const setQueryStringDebounced = useDebounced(setQueryString)

  const { data: allLocations = [] } = useQuery({
    queryKey: ['fetch', { loginUrl }],
    queryFn: () => fetchLocations({ loginUrl }),
    retryOnMount: false,
    onError: reportError
  })

  const { countyOptions, cityOptions, results } = useFilterAndSearch({ allLocations, country, textQuery, city })

  return (
    <div className={styles.componentPage}>
      {/* // TODO: Refactor SectionShadowCasting* component, replace SectionWhite with '' https://kaliber.atlassian.net/browse/RITC-1262 */}
      <SectionWhite padding='lg' dataX='hero'>
        <div className={styles.hero}>
          <UppercaseHeadingXlTitleSubTitle
            subtitle={__`instore-hero-subtitle`}
            title={__`instore-hero-title`}
          />
        </div>
      </SectionWhite>

      <section data-x='filters'>
        <ContainerMd layoutClassName={styles.filtersLayout}>
          <Filters
            onCityChange={handleCityChange}
            onCountryChange={handleCountryChange}
            onTextQueryChange={handleTextQueryChange}
            {...{ country, city, countyOptions, cityOptions, textQuery }}
          />
        </ContainerMd>
      </section>

      <SectionWhite dataX='results' layoutClassName={styles.resultsLayout}>
        <ContainerMd>
          {results.length
            ? <Results {...{ results }} />
            : <NoResultsMessage />
          }
        </ContainerMd>
      </SectionWhite>

    </div>
  )

  function handleTextQueryChange(value) {
    setQueryStringDebounced(x => ({ ...x, textQuery: value }))
  }

  function handleCountryChange(x) {
    setQueryString(previous => ({ ...previous, country: x, city: null }))
  }

  function handleCityChange(x) {
    setQueryString(previous => ({ ...previous, city: x }))
  }
}

function NoResultsMessage() {
  const { __ } = useTranslate()
  return <p className={styles.componentNoResultsMessage}>{__`instore-no-results-message`}</p>
}

function Results({ results }) {
  return (
    <div className={styles.componentResults}>
      <ul className={styles.list}>
        {results.map(result => {
          const item = result.item || result
          return (
            <li key={item.stepId}>
              <Item {...{ item }} />
            </li >
          )
        })}
      </ul>
    </div>
  )
}

function Item({ item }) {
  const { __ } = useTranslate()
  const { host } = useClientConfig()
  const locationName = item.locationName
  const locationId = item.locationId && '(' + item.locationId + ')'

  return (
    <div className={styles.componentItem}>
      <HeadingSm
        h='3'
        layoutClassName={styles.titleLayout}
        title={<>{locationName}{locationId}</>}
      />
      <TextSm
        text={`${item.address}, ${item.city}, ${item.country}`}
        layoutClassName={styles.detailsLayout}
      />
      <ButtonGhostRoundedIcon
        dataX='link-to-store-page'
        icon={iconArrowRight}
        ariaLabel={__`link-to-job`} // aanpassen
        layoutClassName={styles.buttonLayout}
        href={createInstoreUrl({ locationName: item.locationName, stepId: item.stepId, host })}
      />
    </div>
  )
}

function useFilterAndSearch({ allLocations, country, textQuery, city }) {
  const countyOptions = React.useMemo(
    () => createSelectOptions(allLocations, 'countryName'),
    [allLocations]
  )

  const locationsFiltered = React.useMemo(
    () => filterLocation(allLocations, { country, city }),
    [allLocations, country, city]
  )

  const cityOptions = React.useMemo(
    () => country ? createSelectOptions(locationsFiltered, 'city') : [],
    [locationsFiltered, country]
  )

  const fuse = React.useMemo(
    () => new Fuse(locationsFiltered, {
      keys: ['locationName', 'locationId', 'postalCode', 'address'],
      minMatchCharLength: 2,
      findAllMatches: true,
    }),
    [locationsFiltered]
  )

  const searchResult = React.useMemo(() => fuse.search(textQuery), [fuse, textQuery])

  const result = (
    textQuery ? searchResult :
    (country || city)  ? locationsFiltered :
    []
  )

  return {
    results: result.length > 20 ? result.slice(0, 20) : result,
    countyOptions,
    cityOptions,
  }
}

function Filters({ country, city, textQuery, countyOptions, cityOptions, onCityChange, onCountryChange, onTextQueryChange }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentFilters}>
      <SearchField
        value={textQuery}
        onChange={onTextQueryChange}
      />

      <FormFieldSelectLightSimple
        name='country'
        value={country}
        options={countyOptions}
        placeholder={__`select-country`}
        eventHandlers={{ onChange: onCountryChange }}
      />

      <FormFieldSelectLightSimple
        value={city}
        eventHandlers={{ onChange: onCityChange }}
        placeholder={__`select-city`}
        options={cityOptions}
        name='city'
      />
    </div>
  )
}

function SearchField({ value: initialValue, onChange }) {
  const [value, setValue] = React.useState(initialValue)
  const { __ } = useTranslate()

  return (
    <FormFieldTextLightSimple
      name="search"
      placeholder={__`search-field-locations`}
      eventHandlers={{
        onChange: e => {
          const { value } = e.target
          setValue(value)
          onChange(value)
        }
      }}
      {...{ value }}
    />
  )
}

async function fetchLocations({ loginUrl }) {
  const response = await fetch(routeMap.api.v1.locations(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
  })

  if (response.status === FORBIDDEN) {
    window.location.href = loginUrl
  }

  return handleResponse(response)
}

function createSelectOptions(locations, fieldName) {
  return Array.from(new Set(locations.map(x => x[fieldName])))
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b))
    .map(x => ({ value: x, label: x }))
}

function filterLocation(locations, filterValues) {
  return locations.filter(location =>
    (!filterValues.country || location.countryName === filterValues.country) &&
    (!filterValues.city || location.city === filterValues.city)
  )
}
