export const {
  OK,
  MOVED_PERMANENTLY, FOUND, TEMPORARY,
  BAD_REQUEST, NOT_FOUND,
  INTERNAL_SERVER_ERROR,
  ACCEPTED,
  FORBIDDEN, UNAUTHORIZED
} = {
  OK: 200,
  ACCEPTED: 202,
  MOVED_PERMANENTLY: 301, FOUND: 302, TEMPORARY: 307,
  BAD_REQUEST: 400, NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  FORBIDDEN: 403, UNAUTHORIZED: 401,
}
